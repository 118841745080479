import { createRoot } from "react-dom/client";
const container = document.getElementById("root");
const root = createRoot(container);

import { SnackbarProvider } from "notistack";
import { closeSnackbar } from "notistack";
import CancelIcon from "@mui/icons-material/Cancel";
import { enqueueSnackbar } from "notistack";
import Button from "@mui/material/Button";

import App from "./App";
import "./index.css";
//import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from "react-router-dom";

import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  ApolloProvider,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { persistCache, LocalStorageWrapper } from "apollo3-cache-persist";

import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { createClient } from "graphql-ws";
import { RetryLink } from "@apollo/client/link/retry";

import { getMainDefinition } from "@apollo/client/utilities";

const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      "X-Hasura-Public-Secret-Key":
        "GkK9Mjfnxq44Zjs4QamHeduqy6ABM2PLvEmkLJuGNeMShdRMhZSPW6adj78qF6BB",
    },
  };
});

const httpLink = createHttpLink({
  uri: "https://data-eu.swimify.com/v1/graphql",
  //uri: "/graphql",
  //fetchOptions: {
  //  mode: 'no-cors', // Setting the fetch mode to 'no-cors'
  //},
});

let timedOut = null;

const defaultOptions = {
  watchQuery: {
    fetchPolicy: "cache-and-network",
  },
};

// setting configuration for websocket connect for subscription
const wsLink = new GraphQLWsLink(
  createClient({
    url: "wss://data-eu.swimify.com/v1/graphql",
    reconnect: true,
    retryAttempts: Infinity,
    shouldRetry: () => true,
    keepAlive: 10000,

    on: {
      ping: (received) => {
        if (!received /* sent */) {
          timedOut = setTimeout(() => {
            // a close event `4499: Terminated` is issued to the current WebSocket and an
            // artificial `{ code: 4499, reason: 'Terminated', wasClean: false }` close-event-like
            // object is immediately emitted without waiting for the one coming from `WebSocket.onclose`
            //
            // calling terminate is not considered fatal and a connection retry will occur as expected
            //
            // see: https://github.com/enisdenjo/graphql-ws/discussions/290
            wsLink.client.terminate();
          }, 5_000);
        }
      },
      pong: (received) => {
        if (received) {
          clearTimeout(timedOut);
        }
      },
    },

    connectionParams: () => ({
      headers: {
        "X-Hasura-Public-Secret-Key":
          "GkK9Mjfnxq44Zjs4QamHeduqy6ABM2PLvEmkLJuGNeMShdRMhZSPW6adj78qF6BB",
      },
    }),
  })
);

/*
const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return definition.kind === "OperationDefinition" && definition.operation === "subscription";
  },
  authLink.concat(wsLink), // web socket connection for subscriptions
  authLink.concat(httpLink) // httpLink // http connection for query and mutation
); */

const link = new RetryLink({
  initial: 500,
  attempts: (count, operation, error) => {
    const maxAttempts = 6;
    if (error && error.message === "Failed to fetch") {
      const operationName = operation ? operation.operationName : "unknown";
      if (count < maxAttempts) {
        console.debug(
          "Network error, will retry operation",
          operationName + " (" + count + "/" + maxAttempts + ")"
        );
        return true;
      } else {
        console.error(
          "Network error, maximum attempts reached",
          operationName,
          "(" + count + "/" + maxAttempts + ")"
        );
        const errorMessage = error ? " '" + error.message + "' " : "";
        const snackAction = (snackbarId) => (
          <>
            <Button
              sx={{ marginRight: "0.5rem" }}
              variant="contained"
              onClick={() => {
                closeSnackbar(snackbarId);
              }}
            >
              Sulje
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                window.location.reload();
              }}
            >
              Lataa sivu uudelleen
            </Button>
          </>
        );
        enqueueSnackbar(
          "Tietojen haku epäonnistui." +
            errorMessage +
            "Jos ongelma jatkuu, voit yrittää ladata sivun uudelleen, poistaa ja asentaa sovelluksen uudestaan tai yrittää myöhemmin.",
          {
            action: snackAction,
            variant: "warning",
            preventDuplicate: true,
            persist: true,
          }
        );

        return false;
      }
    } else {
      console.error("Error, will not retry operation", operation);
      const errorMessage = error ? " '" + error.message + "' " : "";
      const snackAction = (snackbarId) => (
        <>
          <Button
            sx={{ marginRight: "0.5rem" }}
            variant="contained"
            onClick={() => {
              closeSnackbar(snackbarId);
            }}
          >
            Sulje
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              window.location.reload();
            }}
          >
            Lataa sivu uudelleen
          </Button>
        </>
      );
      enqueueSnackbar(
        "Tietojen haku epäonnistui." +
          errorMessage +
          "Jos ongelma jatkuu, voit yrittää ladata sivun uudelleen, poistaa ja asentaa sovelluksen uudestaan tai yrittää myöhemmin.",
        {
          action: snackAction,
          variant: "warning",
          preventDuplicate: true,
          persist: true,
        }
      );

      return false;
    }
  },
}).split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  authLink.concat(wsLink), // web socket connection for subscriptions
  authLink.concat(httpLink) // httpLink // http connection for query and mutation
);

const cache = new InMemoryCache();
await persistCache({
  cache,
  storage: new LocalStorageWrapper(window.localStorage),
});

function openFeedback() {
  window.open(
    "https://docs.google.com/forms/d/e/1FAIpQLSddYMvm_2saOWYWuUT11-Q91V58QmENl-NYZuvCD7OxWwL1mg/viewform?embedded=true"
  );
}

const client = new ApolloClient({
  link: link,
  cache: cache,
  defaultOptions: defaultOptions,
});

const isDebugMode =
  Boolean(window?.localStorage?.getItem("debugmode")) || false;

if (isDebugMode) {
  root.render(
    <BrowserRouter>
      <SnackbarProvider
        action={(snackbarId) => (
          <CancelIcon onClick={() => closeSnackbar(snackbarId)} />
        )}
        dense={true}
      >
        <ApolloProvider client={client}>
          <App />
        </ApolloProvider>
      </SnackbarProvider>
    </BrowserRouter>
  );
} else {
  root.render(
    <BrowserRouter>
      <SnackbarProvider
        action={(snackbarId) => (
          <CancelIcon onClick={() => closeSnackbar(snackbarId)} />
        )}
        dense={true}
      >
        <div style={{ margin: "12px" }}>
          Pahoittelut, mutta Uinnit.app on Swimifyn muutosten vuoksi ainakin
          toistaiseksi pois käytöstä.
        </div>
        <div style={{ margin: "12px" }}>
          Voit käyttää Swimifyn palveluita suoraan osoitteessa{" "}
          <a
            href="https://live.swimify.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            live.swimify.com
          </a>
          .
        </div>
        <div style={{ margin: "12px", marginTop: "48px" }}>
          Jos haluat, voit edelleen antaa palautetta Uinnit.app-palvelusta:
        </div>
        <div
          style={{ margin: "12px", position: "relative" }}
          onClick={() => openFeedback()}
        >
          <a href="#" onClick={() => openFeedback()}>
            Anna palautetta
          </a>
        </div>
      </SnackbarProvider>
      <div
        style={{
          position: "fixed",
          bottom: "0px",
          right: "0px",
          width: "24px",
          height: "24px",
        }}
        onClick={() => {
          window?.localStorage?.setItem("debugmode", true);
          window.location.reload();
        }}
      ></div>
    </BrowserRouter>
  );
}
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
//serviceWorker.unregister();
