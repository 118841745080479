import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import { Link } from "react-router-dom";

export function FrontPageWelcome() {
  const theme = useTheme();

  return (
    <>
    <Card
      elevation={0}
      sx={{
        margin: theme.spacing(1),
        padding: theme.spacing(1),
      }}
    >
      <Typography variant="body2">
        Uinnit.app on sovellus jolla voit seurata omien uimareidesi kisan etenemistä ja vertailla tuloksia Uimaliiton asettamiin aikarajoihin sekä uimarin
        aiempiin suorituksiin.
      </Typography>
      <Button style={{ marginTop: theme.spacing(2) }} variant="outlined" color="primary" component={Link} to={"/competitions"}>
        Valitse kilpailu
      </Button>
    </Card>
    </>
    
  );
}
